@import 'themes';

$slogan-icon-sm: 36px;
$slogan-icon-border-radius-sm: $slogan-icon-sm / 2;
$slogan-icon-padding-sm: 8px;
$slogan-icon-box-shadow-outer-sm: 2px;
$slogan-icon-md: 64px;
$slogan-icon-border-radius-md: $slogan-icon-md / 2;
$slogan-icon-padding-md: 16px;
$slogan-icon-box-shadow-outer-md: 4px;
