.flex-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-viewport-min {
  min-width: 100vw;
  min-height: 100vh;
}

.text-emphasis {
  color: var(--text-highlight);
}

.sm-only {
  @media (min-width: $screen-md-min) {
    display: none;
  }
}

.sr-only {
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  padding: 0;
  white-space: nowrap;
  border: 0;
}
