$logo-initial-scale: 5;

$logo-portrait-scale: 0.8;
$logo-landscape-scale: 0.9;

.logo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.logo {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform: scale($logo-initial-scale);

  @media (orientation: portrait) {
    animation: animateLogoPortrait 1s forwards;
  }

  @media (orientation: landscape) {
    animation: animateLogoLandscape 1s forwards;
  }

  svg {
    position: absolute;
    top: 50%;
    transition: $transition-default;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: 0 0 0 4vw var(--background-darker);
    fill: var(--background-lighter);
  }
}

@keyframes animateLogoPortrait {
  from {
    transform: scale($logo-initial-scale);
  }

  to {
    transform: scale($logo-portrait-scale);
  }
}

@keyframes animateLogoLandscape {
  from {
    transform: scale($logo-initial-scale);
  }

  to {
    transform: scale($logo-landscape-scale);
  }
}
