html { line-height: 1.15; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
