.home {
  max-width: 100vw;
  padding: 0 15px;
  text-align: center;

  &__icons {
    display: flex;
    height: $slogan-icon-sm;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: center;

    // @note On mobile devices, hide rest of the icons when a single one is expanded.
    // Required in order for the long items to fit (e.g. email or resume label).
    @media (max-width: ($screen-md-min - 1)) {
      &.is-animated {
        .icon-link {
          &:not(.is-active) {
            max-width: 0;
            opacity: 0;
          }
        }
      }
    }

    @media (min-width: $screen-md-min) {
      height: $slogan-icon-md;
    }
  }

  li {
    @function get-icon-delay($index) {
      $delay: ($index * 0.1);
      @return '#{$delay}s';
    }

    white-space: nowrap;
    // Prevent triggering click and hover states before animation is started.
    pointer-events: none;

    &.start-animation {
      transform: scale(0.7);
      animation: scaleToSize 1s forwards;
      pointer-events: all;

      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          .icon-link__icon {
            animation: fadeInSlide 0.5s #{get-icon-delay($i)} forwards;
          }
        }
      }
    }
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.3rem;

    @media (min-width: $screen-lg-min) {
      font-size: 4rem;
    }
  }

  p {
    margin-top: 0.75em;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.15rem;

    @media (min-width: $screen-lg-min) {
      font-size: 1.75rem;
    }
  }

  &__delayed-subtitle {
    // Prevent page jumping because of multiple lines of text.
    white-space: nowrap;
    opacity: 0;

    &.start-animation {
      animation: fadeInSlide 2s forwards;
      white-space: normal;
    }
  }
}
