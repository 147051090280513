.icon-link {
  position: relative;
  display: flex;
  max-width: 20em;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: opacity 0.5s, max-width 0.2s;
  white-space: nowrap;
  text-decoration: none;
  text-transform: none;
  opacity: 1;
  color: var(--text-color);
  outline: none;
  font-size: 1.5rem;

  @media (min-width: $screen-md-min) {
    margin-right: 2px;
    padding-right: 2px;

    &::after {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      display: none;
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      margin-top: -2px;
      margin-left: 1px;
      padding: 2px 0 2px 4px;
      content: '';
      opacity: 0.1;
      border-radius: $slogan-icon-md;
      background-color: var(--text-color);
    }

    &:focus {
      &::after {
        display: block;
      }
    }
  }

  &.is-active {
    .icon-link__icon {
      box-shadow: inset 0 0 0 ($slogan-icon-box-shadow-outer-sm * 1.5) $accent, 0 0 0 ($slogan-icon-box-shadow-outer-sm) var(--text-color);

      svg {
        fill: $accent !important; // sass-lint:disable-line no-important
      }

      @media (min-width: $screen-md-min) {
        box-shadow: inset 0 0 0 ($slogan-icon-box-shadow-outer-md * 1.5) $accent, 0 0 0 ($slogan-icon-box-shadow-outer-md) var(--text-color);
      }
    }

    .icon-link__title {
      max-width: 20ch;
    }
  }

  &__icon {
    position: relative;
    display: block;
    width: $slogan-icon-sm;
    height: $slogan-icon-sm;
    margin-left: 5px;
    transition: $transition-default;
    opacity: 0;
    border-radius: $slogan-icon-border-radius-sm;
    background-color: var(--text-color);

    box-shadow: inset 0 0 0 $slogan-icon-box-shadow-outer-sm var(--background), 0 0 0 ($slogan-icon-box-shadow-outer-sm / 2) var(--text-color);

    @media (min-width: $screen-md-min) {
      width: $slogan-icon-md;
      height: $slogan-icon-md;
      margin-left: 10px;
      border-radius: $slogan-icon-border-radius-md;
      box-shadow: inset 0 0 0 $slogan-icon-box-shadow-outer-md var(--background), 0 0 0 ($slogan-icon-box-shadow-outer-md / 2) var(--text-color);
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      padding: $slogan-icon-padding-sm;
      transition: $transition-default;
      fill: var(--background);

      @media (min-width: $screen-md-min) {
        padding: $slogan-icon-padding-md;
      }
    }
  }

  &__title {
    display: inline-block;
    overflow: hidden;
    max-width: 0;
    padding-left: 5px;
    transition: max-width 0.7s 0.2s ease-out;
    line-height: $slogan-icon-sm;

    @media (min-width: $screen-md-min) {
      padding-left: 10px;
      transition: max-width 1s ease-out;
      line-height: $slogan-icon-md;
    }
  }
}
