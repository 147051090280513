@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blinkToHidden {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInSlide {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  30% {
    transform: translateY(0);
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleToSize {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}
