$switcher-icon-small: 16px;
$switcher-icon-large: 24px;
$switcher-icon-padding: 10px;

.theme-switcher {
  position: fixed;
  z-index: 5;
  bottom: 5px;
  left: 5px;
  display: flex;

  &__button {
    position: relative;
    padding: $switcher-icon-padding;
    cursor: pointer;
    transition: $transition-default;
    opacity: 0.4;
    border: 0;
    background: none;

    &.is-active {
      opacity: 1;
    }
  }

  svg {
    width: $switcher-icon-small;
    height: $switcher-icon-small;
    fill: var(--text-color);

    @media (min-width: $screen-md-min) {
      width: $switcher-icon-large;
      height: $switcher-icon-large;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }
}
