::selection {
  color: var(--text-highlight);
  background-color: $accent;
}

.app {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100vh;
  transition: $transition-default;
  color: var(--text-color);
  background-color: var(--background);

  main {
    position: relative;
    z-index: 1;
  }
}
