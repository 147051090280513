// Required CSS resets.
* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

